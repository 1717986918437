import React from "react";
import { Container, Spinner } from "react-bootstrap";
import "./style.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Space, Table, Popconfirm, Button, Image, Input, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import useToast from "../../provider/Toast/useToast";
import moment from "moment";
import { getRole, converedTwoDecimal } from "../../utils/cookie";


const InsideBussinessReportDetail = ({data,bussinessReportRole,setIsInsideBussinessReportDetailShow}) => {
    const [bussinessReportDeatilList, setBussinessReportDeatilList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [userRole, setUserRole] = React.useState(getRole())


    const location = useLocation();
    // const data = location
    // const bussinessReportRole = location.pathname.split('/')[2]
    console.log("bussinessReportRole", bussinessReportRole)
    const toast = useToast();

    const navigate = useNavigate();

    const { Text } = Typography;


    React.useEffect(() => {
        console.log(data)

        const mergedData = data.games.reduce((acc, curr) => {
            // const existing = acc.find(item => item._id === curr._id);
            const existing = acc.find(item => item.gameName == curr.gameName);
            if (existing) {
                existing.adminCommission += curr.adminCommission || 0;
                existing.agentCommission += curr.agentCommission || 0;
                existing.distributorCommission += curr.distributorCommission || 0;
                existing.partnerCommission += curr.partnerCommission || 0;
                existing.subDistributorCommission += curr.subDistributorCommission || 0;
                existing.playPoints += curr.playPoints || 0;
                existing.winPoints += curr.winPoints || 0;
            } else {
                acc.push(curr);
            }
            return acc;
        }, []);

        let newMergeData = mergedData.map((item) => {
            return {
                ...item, ... (bussinessReportRole === 'admin' && { percentage: ((item.adminCommission / (item.adminCommission + item.partnerCommission + item.distributorCommission + item.subDistributorCommission + item.agentCommission)) * 100) })
                , ... (bussinessReportRole === 'partner' && { percentage: ((item.partnerCommission / (item.adminCommission + item.partnerCommission + item.distributorCommission + item.subDistributorCommission + item.agentCommission)) * 100) }), ... (bussinessReportRole === 'distributor' && { percentage: ((item.distributorCommission / (item.adminCommission + item.partnerCommission + item.distributorCommission + item.subDistributorCommission + item.agentCommission)) * 100) }), ... (bussinessReportRole === 'sub-distributor' && { percentage: ((item.subDistributorCommission / (item.adminCommission + item.partnerCommission + item.distributorCommission + item.subDistributorCommission + item.agentCommission)) * 100) })
                , ... (bussinessReportRole === 'agent' && { percentage: ((item.agentCommission / (item.adminCommission + item.partnerCommission + item.distributorCommission + item.subDistributorCommission + item.agentCommission)) * 100) })
            }
        })
        console.log(mergedData, 'mergedData')
        console.log("newMergeData", newMergeData)
        setBussinessReportDeatilList(newMergeData)


    }, [])


    const columns = [

        {
            title: 'GAME NAME',
            dataIndex: 'gameName',
            key: 'Game Name',
        },
        {
            title: 'PERCENTAGE',
            dataIndex: 'percentage',
            key: 'percentage',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'TOTAL BET',
            dataIndex: 'playPoints',
            key: 'Total Bet',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'TOTAL WON',
            dataIndex: 'winPoints',
            key: 'Total Won',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'END POINT',
            dataIndex: 'endPoints',
            key: 'End Point',
            render: (_, record) => {
                const endPoint = (record?.playPoints || 0) - (record?.winPoints || 0);
                return <>{converedTwoDecimal(endPoint)}</>;
            }

        },
        ...(bussinessReportRole == 'partner' ? [{
            title: 'MARGIN',
            dataIndex: 'distributorCommission',
            key: 'Margin',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'NET',
            dataIndex: 'net',
            key: 'Net',
            render: (_, record) => {
                const endPoint = ((record?.playPoints || 0) - (record?.winPoints || 0)) - (record?.distributorCommission || 0);
                return <>{converedTwoDecimal(endPoint)}</>;
            }
        },
        {
            title: 'PARTNER PROFIT',
            dataIndex: 'partnerCommission',
            key: 'Partner Profit',
            render: text => converedTwoDecimal(text) || '0',
        }
        ]
            : []),
        ...(bussinessReportRole == 'distributor' ? [{
            title: 'MARGIN',
            dataIndex: 'subDistributorCommission',
            key: 'Margin',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'NET',
            dataIndex: 'net',
            key: 'Net',
            render: (_, record) => {
                const endPoint = ((record?.playPoints || 0) - (record?.winPoints || 0)) - (record?.subDistributorCommission || 0);
                return <>{converedTwoDecimal(endPoint)}</>;
            }
        },
        {
            title: 'DISTRIBUTOR PROFIT',
            dataIndex: 'distributorCommission',
            key: 'Distributor Profit',
            render: text => converedTwoDecimal(text) || '0',
        }
        ] :
            []
        ),
        ...(bussinessReportRole == 'sub-distributor' ? [{
            title: 'MARGIN',
            dataIndex: 'agentCommission',
            key: 'Margin',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'NET',
            dataIndex: 'net',
            key: 'Net',
            render: (_, record) => {
                const endPoint = ((record?.playPoints || 0) - (record?.winPoints || 0)) - (record?.agentCommission || 0);
                return <>{converedTwoDecimal(endPoint)}</>;
            }
        },
        {
            title: 'SUB DISTRIBUTOR PROFIT',
            dataIndex: 'subDistributorCommission',
            key: 'Sub Distributor Profit',
            render: text => converedTwoDecimal(text) || '0',
        }
        ] : []),
        ...(bussinessReportRole == 'agent' ? [
            {
                title: 'AGENT PROFIT',
                dataIndex: 'agentCommission',
                key: 'Agent Profit',
                render: text => converedTwoDecimal(text) || '0',
            }
        ] : []
        ),
        ...(bussinessReportRole == 'user' ? [
            {
                title: 'USER PROFIT',
                dataIndex: 'winPoints',
                key: 'User Profit',
                render: text => converedTwoDecimal(text) || '0',
            }
        ] : []),
        {
            title: 'BONUS',
            dataIndex: 'Bonus',
            key: 'Bonus',
            render: text => converedTwoDecimal(text) || '0', // Use a render function to ensure consistency
        },

    ]




    return (
        <main id="bussinessReport">
            <Container className="admin_padding">
                {/* <div className="container breadcumb">
                    <h1>{bussinessReportRole?.toUpperCase()} BUSSINESS REPORT DETAIL</h1>
                    <p className="fw-bold "><span style={{ color: '#2596be', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / {bussinessReportRole?.toUpperCase()} BUSSINESS REPORT DETAIL</p>
                </div> */}

                <div className="admin_padding_table" style={{ padding: 0, marginTop: '15px' }}>
                    <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: '#146eb4', padding: "1rem" }}>TN SUB DISTRIBUTOR PERCENTAGE CALC</h6>
                    </div>
                    <div className="table-container" style={{ margin: "20px" }}>
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        {
                            loading ?
                                <Table dataSource={bussinessReportDeatilList} scroll={{ x: 525 }} />
                                :
                                <Table className="bussiness-detail-table" bordered dataSource={bussinessReportDeatilList} columns={columns} scroll={{ x: 525 }}
                                    pagination={false}

                                    summary={(pageData) => {
                                        let totalPlayPoint = 0;
                                        let totalWonPoint = 0;
                                        let totalEndPoint = 0;
                                        let totalMergin = 0;
                                        let totalNet = 0;
                                        let totalProfit = 0

                                        pageData.forEach((data) => {
                                            totalPlayPoint += data.playPoints;
                                            totalWonPoint += data.winPoints;
                                            totalEndPoint += data.playPoints - data.winPoints;
                                            if (bussinessReportRole == 'partner') {
                                                totalMergin += data.distributorCommission
                                                totalNet += ((data?.playPoints || 0) - (data?.winPoints || 0)) - (data?.distributorCommission || 0);
                                                totalProfit += data.partnerCommission
                                            }
                                            if (bussinessReportRole == 'distributor') {
                                                totalMergin += data.subDistributorCommission
                                                totalNet += ((data?.playPoints || 0) - (data?.winPoints || 0)) - (data?.subDistributorCommission || 0);
                                                totalProfit += data.distributorCommission
                                            }
                                            if (bussinessReportRole == 'sub-distributor') {
                                                totalMergin += data.agentCommission
                                                totalNet += ((data?.playPoints || 0) - (data?.winPoints || 0)) - (data?.agentCommission || 0);
                                                totalProfit += data.subDistributorCommission
                                            }
                                            if (bussinessReportRole == 'agent') {
                                                totalProfit += data.agentCommission
                                            }
                                            if (bussinessReportRole == 'user') {
                                                totalProfit += data.winPoints
                                            }

                                        });
                                        return (
                                            <>
                                                <Table.Summary.Row style={{ backgroundColor: 'rgb(251 251 251)', fontWeight: 'bold' }}>
                                                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>
                                                        <Text>{converedTwoDecimal(totalPlayPoint)}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={5}>
                                                        <Text>{converedTwoDecimal(totalWonPoint)}</Text>
                                                    </Table.Summary.Cell> <Table.Summary.Cell index={6}>
                                                        <Text>{converedTwoDecimal(totalEndPoint)}</Text>
                                                    </Table.Summary.Cell>
                                                    {bussinessReportRole != 'agent' && bussinessReportRole != 'user' &&
                                                        <>
                                                            <Table.Summary.Cell index={7}>
                                                                <Text>{converedTwoDecimal(totalMergin)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={8}>
                                                                <Text>{converedTwoDecimal(totalNet)}</Text>
                                                            </Table.Summary.Cell>
                                                        </>
                                                    }
                                                    <Table.Summary.Cell index={9}>
                                                        <Text>{converedTwoDecimal(totalProfit)}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={10}>
                                                        <Text>0</Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>

                                            </>
                                        );
                                    }}
                                />

                        }
                    </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  // className="footer-btns"
                >
                  {
                    <Button type="submit" className="input_file_button"  style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }} onClick={() => setIsInsideBussinessReportDetailShow(false)}>
                      CANCEL
                    </Button>
                  }
                </div>
            </Container>
        </main>
    );
};

export default InsideBussinessReportDetail;

