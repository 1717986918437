import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";
import { getRole ,converedTwoDecimal} from "../../../utils/cookie";
import {  useNavigate } from "react-router-dom";


const PartnerPointModel = () => {
  const toast = useToast();
  const [userRole, setUserRole] = useState(getRole());
  const [partnerList, setPartnerList] = useState([]);
  const [myPoints, setMyPoints] = useState(0);
  const [userDetail, setUserDetail] = useState({})
  const [error, setError] = useState('')


  const navigate = useNavigate();

  const getPartnerList = async () => {
    try {
      const response = await apiAdminConfig.get("users/get/partner");
      if (response?.data?.results?.length > 0) {
        const partnerList = response?.data?.results.map((item) => ({
          label: item.userName,
          value: item.id,
          point:item.points
        }));
        setPartnerList(partnerList);
      }
    } catch (error) {
      console.log("gameListError", error);
    }
  };

  const getMyPoints = async () => {
    try {
      const response = await apiAdminConfig.get("auth/me");
      if (response?.data) {
        setMyPoints(response?.data?.points || 0);
      }
    } catch (error) {
      console.log("gameListError", error);
    }
  };

  useEffect(() => {
    getPartnerList();
    getMyPoints();
  }, []);

  const [initialValues, setInitialValues] = useState({
    partner: '',
    points: '',
    verifyPassword: '',
    type: '',
  });

  const validationSchema = yup.object().shape({
    partner: yup.string().required("Partner is a required field"),
    points: yup.string().required("Points is a required field"),
    verifyPassword: yup.string().required("Transaction Password is a required field"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      setError('')

      try {
        const response = await apiAdminConfig.post("points/add-points", formik.values);
        if (response?.status === 200) {
          setUserDetail(response?.data?.result)


          getMyPoints();
          formik.resetForm();
        } else {
          toast({
            message: response?.data?.message,
            bg: 'danger',
          });
        }
      } catch (error) {
        const { response } = error;
        if (response?.status == 400) {
          setUserDetail(response?.data?.result)
          setError(response?.data.message)
      } else {
          toast({
              message: response?.data?.message,
              bg: 'danger',
          });
      }
      }
    },
  });

  const handleAddPoints = () => {
    formik.setFieldValue("type", "credit");
    formik.handleSubmit();
  };

  const handleSubPoints = () => {
    formik.setFieldValue("type", "debit");
    formik.handleSubmit();
  };

  return (
    <main id="events">
      <Container className="admin_padding">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="table-hader-container"
        >
          <div className=" breadcumb">
            <h1 >ADD POINTS</h1>
            <p className="fw-bold "><span style={{ color: '#2596be', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / Add Points</p>
          </div>
        </div>
        <div className="admin_form">
          <div className="detail-heading">
            <h6 className="text-white">ENTER DETAILS</h6>
          </div>
          <Row className="admin_add_form">
            <Col>
              <Form noValidate>
              {
                                    (userDetail?.type === 'credit' && !error )&& <>
                                        <div style={{ textAlign: "center" }}>
                                            <p style={{ color: 'green', margin: 'auto', textAlign: "center" }}>SUCCESSFULLY ADDED</p>
                                            <p style={{ margin: 'auto', textAlign: "center" }}>USER_NAME - {userDetail?.userName}</p>
                                            <p style={{ textAlign: "center" }}>TRANSACTION POINTS - {userDetail?.points}</p>
                                        </div>
                                    </>
                                }


                                {
                                   (userDetail?.type === 'debit' && !error) && <>
                                        <div style={{ textAlign: "center" }}>
                                            <p style={{ color: 'green', margin: 'auto', textAlign: "center" }}>SUCCESSFULLY MINUS</p>
                                            <p style={{ margin: 'auto', textAlign: "center" }}>USER_NAME - {userDetail?.userName}</p>
                                            <p style={{ textAlign: "center" }}>TRANSACTION POINTS - {userDetail?.points}</p>
                                        </div>
                                    </>
                                }

{
                                    !!error  && <>
                                        <div style={{ textAlign: "center" }}>
                                            <p style={{ color: 'red', margin: 'auto', textAlign: "center" }}>{error}</p>
                                            <p style={{ margin: 'auto', textAlign: "center" }}>USER_NAME - {userDetail?.userName}</p>
                                            <p style={{ textAlign: "center" }}>TRANSACTION POINTS - {userDetail?.points}</p>
                                        </div>
                                    </>
                                }
                <Row>
                  <Col className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>{userRole.toUpperCase()} TOTAL POINTS</Form.Label>
                      <Form.Control
                        type="text"
                        name="userName"
                        value={converedTwoDecimal(myPoints)}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>TRANSACTION PASSWORD</Form.Label>
                      <Form.Control
                        type="password"
                        name="verifyPassword"
                        placeholder="Enter Transaction Password"
                        value={formik.values.verifyPassword}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.verifyPassword && formik.errors.verifyPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.verifyPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>PARTNER</Form.Label>
                      <Form.Select
                        as="select"
                        name="partner"
                        value={formik.values.partner}
                        onChange={(e) => formik.setFieldValue("partner", e.target.value)}
                        isInvalid={formik.touched.partner && formik.errors.partner}
                      >
                        <option value="">Select Partner</option>
                        {partnerList.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label} --{">"} Available Points : {converedTwoDecimal(item?.point)}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.partner}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>ADD POINTS</Form.Label>
                      <Form.Control
                        type="number"
                        name="points"
                        placeholder="Enter Points"
                        value={formik.values.points}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.points && formik.errors.points}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.points}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <div
                  className="footer-btns"
                  style={{ display: "flex", gap: "10px" }}
                >
                  <Button
                    type="button"
                    className="input_file_button add-point-btn"
                    onClick={handleAddPoints}
                  >
                    ADD POINTS
                  </Button>
                  <Button
                    type="button"
                    className="input_file_button sub-point-btn"
                    onClick={handleSubPoints}
                  >
                    SUB POINTS
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default PartnerPointModel;
