import React from "react";
import { Container, Spinner } from "react-bootstrap";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { Space, Table, Popconfirm, Button, Input } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import useToast from "../../provider/Toast/useToast";
import { apiAdminConfig } from "../../utils/api";
import { getRole,converedTwoDecimal } from "../../utils/cookie";
import moment from "moment";
import { SearchOutlined } from '@ant-design/icons';


const User = () => {
    const [userList, setUserList] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const toast = useToast();
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(25);

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };


    const [userRole, setUserRole] = React.useState(getRole())

    const navigate = useNavigate();

    const getUserList = async () => {
        setLoading(true)
        await apiAdminConfig.get(`users/get/user?search=${search}`).then((response) => {
            if (response?.status == 200) {
                setUserList(response?.data?.results)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false)
            console.log("userError", error);
        })
    }
    React.useEffect(() => {
        getUserList();
    }, [search])

    const onDelete = async (id) => {
        await apiAdminConfig.delete(`users/delete/${id}`).then((response) => {
            if (response?.status === 200) {
                toast({
                    message: 'User Delete successfully',
                    bg: 'success'
                });
                getUserList();
            } else {
                toast({
                    message: response?.data?.message,
                    bg: 'danger'
                });
            }
        }).catch((error) => {
            const { response } = error
            toast({
                message: response?.data?.message,
                bg: "danger",
            });
        })
    }

    const columns = [
        {
            title: 'SR NO',
            dataIndex: '_id',
            key: 'Srno',
            render: (_, record, index) => {
                return (
                    <>
                        {(currentPage - 1) * pageSize + index + 1}
                    </>
                )
            }
        },
        {
            title: 'USER NAME',
            dataIndex: 'userName',
            key: 'UserName',
            render: (_, record) => (
                <button style={{border:'none',color:'#2596be',background:'none',fontWeight:500}} onClick={() =>  navigate(`/WinLucky/user/view`, { state: { data: {...record, userId : record?.id} } })}>
                    {record.userName}
                </button>
            ),
        },
        {
            title: 'POINTS',
            dataIndex: 'points',
            key: 'Points',
             render: text => converedTwoDecimal(text) || '0'
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            key: 'Email',
            render: text => text || '-'
        },
        {
            title: 'PHONE NO',
            dataIndex: 'phone',
            key: 'Phone No',
            render: text => text || '-'
        },
        ...(userRole === "superAdmin" ? [

            {
                title: 'ADMIN NAME',
                dataIndex: 'adminName',
                key: 'Admin Name',
                render: (_, record) => {
                    return (
                        <>
                            {record?.admin?.userName || "N/A"}
                        </>
                    )
                }
            },
            {
                title: 'PARTNER NAME',
                dataIndex: 'partnerName',
                key: 'Partner Name',
                render: (_, record) => {
                    return (
                        <>
                            {record?.partner?.userName || "N/A"}
                        </>
                    )
                }
            }] : []),
        ...(userRole === "partner" || userRole === "superAdmin" ? [
            {
                title: 'DISTRIBUTOR NAME',
                dataIndex: 'distributorName',
                key: 'Distributor Name',
                render: (_, record) => {
                    return (
                        <>
                            {record?.distributor?.userName || "N/A"}
                        </>
                    )
                }
            }] : []),
        ...(userRole === "admin" ? [
            {
                title: 'PARTNER NAME',
                dataIndex: 'partnerName',
                key: 'Partner Name',
                render: (_, record) => {
                    return (
                        <>
                            {record?.partner?.userName || "N/A"}
                        </>
                    )
                }
            },
            {
                title: 'DISTRIBUTOR NAME',
                dataIndex: 'distributorName',
                key: 'Distributor Name',
                render: (_, record) => {
                    return (
                        <>
                            {record?.distributor?.userName || "N/A"}
                        </>
                    )
                }
            }] : []),

        ...(userRole === "admin" || userRole === "distributor" || userRole === "superAdmin" || userRole === "partner" ? [
            {
                title: 'SUB DISTRIBUTOR NAME',
                dataIndex: 'subDistributorName',
                key: 'Sub Distributor Name',
                render: (_, record) => {
                    return (
                        <>
                            {record?.subDistributor?.userName || "N/A"}
                        </>
                    )
                }
            }] : []),

        ...(userRole === "admin" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin" || userRole === "partner" ? [
            {
                title: 'AGENT NAME',
                dataIndex: 'agentName',
                key: 'Agent Name',
                render: (_, record) => {
                    return (
                        <>
                            {record?.agent?.userName || "N/A"}
                        </>
                    )
                }
            }] : []),
        {
            title: 'REGISTER DATE',
            dataIndex: 'createdAt',
            key: 'Register Date',
            render: text => text ? moment(text).format('DD-MM-YYYY') : '-'
        },
        
        {
            title: 'LOCKED BY',
            dataIndex: 'lockedBy',
            key: 'Locked By',
            render: (_, record) => {
                return (
                    <>
                        {record?.lockedBy?.userName || "NULL"}
                    </>
                )
            }
            
        },
        {
            title: 'LOCK STATUS',
            dataIndex: 'lockStatus',
            key: 'Lock Status',
            render: (text) => <span style={{ color: 'red' }}>{text}</span>,
        },
        {
            title: "ACTION",
            dataIndex: "action",
            key: 'Action',
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        title="Edit"
                        style={{ padding: "2px 4px" }}
                        onClick={() => navigate(`/WinLucky/user/form`, { state: { data: record } })}
                    >
                        {" "}
                        <strong>
                            {" "}
                            <EditOutlined
                                style={{
                                    fontWeight: "900",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                }}
                            />{" "}
                        </strong>{" "}
                    </Button>

                    <Popconfirm
                        title="Are you sure want to delete ?"
                        key={record.id}
                        onConfirm={() => onDelete(record.id)}
                    >
                        <Button danger type="primary" title="Delete" style={{ padding: "4px 8px" }}>
                            <strong>
                                <DeleteOutlined
                                    style={{
                                        fontWeight: "900",
                                        fontSize: "16px",
                                        verticalAlign: "middle",
                                    }}
                                />
                            </strong>
                        </Button>
                    </Popconfirm>
                    {/* <Button danger type="primary" title="View" style={{ padding: "4px 8px" }} onClick={() => {
                        navigate(`/WinLucky/user/view`, { state: { data: record } })
                    }}>
                        <strong>
                            <EyeOutlined
                                style={{
                                    fontWeight: "900",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                }}
                            />
                        </strong>
                    </Button> */}
                </Space>
            ),
        }
    ]


    return (
        <main id="users">
            <Container className="admin_padding">
                <div className="container breadcumb">
                    <h1>USER LIST</h1>
                    <p className="fw-bold"><span style={{ color: '#2596be', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / User List</p>
                </div>
                <div className="admin_padding_table" style={{ padding: 0 ,marginTop:'15px'}}>
                <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: '#146eb4', padding: "1rem" }}></h6>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                        <div className="page-size">
                            <span className="fw-bold">Show</span>
                            <select onChange={(e) => setPageSize(e.target.value)}
                                value={pageSize} className="selecte-page-size">
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                            </select>
                            <span className="fw-bold">entries</span>
                        </div>
                        <div className="search_container">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                className="search_input"
                                style={{
                                    borderRadius: "5px",
                                    height: "40px",

                                }}
                            />
                            {/* <Link to="/WinLucky/user/form" className="page_add_btn">
                                Add New
                            </Link> */}
                        </div>

                    </div>
                    <div className="table-container">
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        {
                            loading ?
                                <Table dataSource={userList} scroll={{ x: 525 }} />
                                :
                                <Table bordered dataSource={userList} columns={columns} scroll={{ x: 525 }} style={{margin :'10px'}}
                                    pagination={{
                                        current: currentPage,
                                        pageSize: pageSize,
                                        showSizeChanger: false,
                                        total: userList.length,
                                        onChange: (page, pageSize) => {
                                            setCurrentPage(page);
                                        },
                                        showTotal: (total, range) => `showing ${range[0]}-${range[1]} of ${total} entries`,
                                    }}

                                    onChange={handleTableChange} />

                        }
                    </div>
                </div>
            </Container>
        </main>
    );
};

export default User;
