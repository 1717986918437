import React, { useState } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../provider/Toast/useToast";
import { apiAdminConfig } from "../../utils/api";

const AdminAddPoint = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const [initialValues, setInitialValues] = useState({
    points: '',
  });

  const validationSchema = yup.object().shape({
    points: yup.string().required('Point is required'),
  });

  const { handleSubmit, handleChange, values, errors, touched, resetForm } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      await apiAdminConfig.post(`points/add-points-admin`, values).then((response) => {
        if (response?.status === 201) {
          toast({
            message: 'Add Point successfully',
            bg: 'success'
          });
          resetForm(); // Reset the form after successful submission
        } else {
          toast({
            message: response?.data?.message,
            bg: 'danger'
          });
        }
      }).catch((error) => {
        const { response } = error;
        toast({
          message: response?.data?.message,
          bg: "danger",
        });
      });
    },
  });

  return (
    <main id="events">
      <Container className="admin_padding">
        <div className="admin_add_form">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "14px 0",
              alignItems: "center",
            }}
            className="table-hader-container"
          >
            <div className="breadcumb">
              <h1>ADD POINT</h1>
              <p className="fw-bold ">
                <span style={{ color: '#2596be', cursor: 'pointer' }} onClick={() => { navigate("/") }}>
                  Home
                </span> / Add Point
              </p>
            </div>
          </div>
          <div className="admin_form">
            <div className="detail-heading">
              <h6 className="text-white">ENTER DETAILS</h6>
            </div>
            <Row className="admin_add_form">
              <Col>
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="validationFormik01">
                        <Form.Label>
                          ADD POINT<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="points"
                          value={values.points}
                          placeholder="Enter Point"
                          onChange={handleChange}
                          isInvalid={!!errors.points && touched.points}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.points}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div style={{ display: 'flex', justifyContent: "center" }}>
                    <Button type="submit" className="input_file_button">
                      Save
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>

        </div>
      </Container>
    </main>
  );
};

export default AdminAddPoint;
