import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { useFormik } from "formik";
import { apiAdminConfig } from "../../utils/api";
import { getRole, converedTwoDecimal } from "../../utils/cookie";
import { Table, Input, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import InsideBussinessReportDetail from "../InsideBussinessReportDetail";


const SubDistributorBussinessReport = () => {
    const [bussinessReportList, setBussinessReportList] = React.useState([]);
    const [userRole, setUserRole] = React.useState(getRole());
    const [totalCashIn, setTotalCashIn] = useState(0)
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(100);
    const [search, setSearch] = React.useState("");
    const [bussinessReportData, setBussinessReportData] = React.useState([]);
    const [insideBussinessReportDetail, setInsideBussinessReportDetail] = React.useState('')

    const [partnerList, setPartnerList] = useState([])
    const [disributorList, setDistributorList] = useState([])
    const [subDisributorList, setSubDistributorList] = useState([])
    const [agentList, setAgentList] = useState([])
    const [userList, setUserList] = useState([])
    const [isShowTable, setIsShowTable] = useState(false)
    const [profit, setProfit] = useState({})
    const [isInsideBussinessReportDetailShow, setIsInsideBussinessReportDetailShow] = useState(false)
    let userDetail = localStorage.getItem('userDetail')
    userDetail = JSON.parse(userDetail)

    const role = getRole();


    const { Text } = Typography;

    const navigate = useNavigate();

    const location = useLocation();
    const bussinessReportRole = location.pathname.split('/')[2]

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const initialValues = {
        startDate: '',
        endDate: '',
        pickDate: '',
        device: 'ALL',
        reportPassword: '',
        ...((bussinessReportRole == 'partner') && { partner: '' }),
        ...((bussinessReportRole == 'distributor') && { distributor: '' }),
        ...((bussinessReportRole == 'sub-distributor') && { subDistributor: '' }),
        ...((bussinessReportRole == 'agent') && { agent: '' }),
        ...((bussinessReportRole == 'user') && { user: '' }),
    };

    useEffect(() => {
        console.log("----------------------------****", location)
        if (location?.state?.user?._id) {
            getSubDistributorByDistributor(location?.state?.user?._id)
            getBussinessReportList('', '', '', '', 'goalocal123', '', location?.state?.user?._id)
            setIsShowTable(true)
        } else {
            getSubDistributor();
        }
    }, [bussinessReportRole])

    const getUserProfite = async () => {
        await apiAdminConfig.get(`users/get/under-user`).then((response) => {
            console.log("response3333", response)
            setProfit(response?.data?.result)
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };

    useEffect(() => {
        console.log("----------------------------****")
        getUserProfite()
    }, [])



    const getSubDistributor = async (id = '') => {
        await apiAdminConfig.get(`users/report/user?userBy=subDistributor`).then((response) => {
            if (response?.data?.length > 0) {
                setSubDistributorList(response?.data)
            } else {
                setSubDistributorList([])
                setAgentList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };

    const getSubDistributorByDistributor = async (id = '') => {
        await apiAdminConfig.get(`users/report/userBy?userBy=subDistributor&distributorId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setSubDistributorList(response?.data)
            } else {
                setSubDistributorList([])
                setAgentList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };
    useEffect(() => {
        // setIsShowTable(false)
        setBussinessReportList([])
        setBussinessReportData([])
    }, [bussinessReportRole])


    const getBussinessReportList = async (pickDate = '', toDate = '', fromDate = '', id = '', reportPassword = location?.state?.user?._id ? "goalocal123" : '', device = '', distributorId = '', subDistributorId = '', agentId = '', partner = '') => {

        console.log("reportPassword", reportPassword)
        setLoading(true);
        if (!!reportPassword) {
            setIsShowTable(true)
            try {
                let url = ''

                if (bussinessReportRole == 'sub-distributor') {
                    if (!!subDistributorId) {
                        url = `users/business-report?search=${search}&pickDate=${pickDate}&toDate=${toDate}&fromDate=${fromDate}&reportPassword=${reportPassword}&device=${device}&reportBy=subDistributor&subDistributorId=${subDistributorId}&distributorId=${distributorId}`
                    }
                    else {
                        url = `users/business-report?search=${search}&pickDate=${pickDate}&toDate=${toDate}&fromDate=${fromDate}&reportPassword=${reportPassword}&device=${device}&reportBy=subDistributor&distributorId=${distributorId}`
                    }
                }

                const response = await apiAdminConfig.get(
                    url
                    // `points/transactions?type=OUT&pickDate=`

                );

                if (response?.status === 200) {
                    let totalPlayPoint = 0;
                    let totalWonPoint = 0;
                    let totalEndPoint = 0;
                    let totalMergin = 0;
                    let totalNet = 0;
                    let totalProfit = 0
                    let totalAg = 0
                    let totalPl = 0
                    let totalDistributorProfit = 0
                    response?.data.forEach((data) => {
                        totalPlayPoint += data.totalPlayPoints;
                        totalWonPoint += data.totalWinPoints;
                        totalEndPoint += data.totalPlayPoints - data.totalWinPoints;
                        totalDistributorProfit += data.totalDistributorCommission
                        totalNet += ((data?.totalPlayPoints || 0) - (data?.totalWinPoints || 0)) - ((data?.totalSubDistributorCommission || 0) + (data?.totalAgentCommission || 0));

                        if (bussinessReportRole == 'sub-distributor' || bussinessReportRole == 'distributor' || bussinessReportRole == 'partner') {
                            totalAg += data.AG
                        }
                        if (bussinessReportRole == 'sub-distributor' || bussinessReportRole == 'distributor' || bussinessReportRole == 'partner') {
                            totalPl += data.PL
                        }
                    })
                    setBussinessReportList(response?.data);

                    const bussinessreportTotal = [{
                        totalPlayPoints: totalPlayPoint,
                        totalWinPoints: totalWonPoint,
                        endPoints: totalEndPoint,
                        totalMerginPoints: totalMergin,
                        totalNetPoints: totalNet - totalDistributorProfit,
                        totalProfitPoints: totalDistributorProfit,
                    }]

                    setBussinessReportData(bussinessreportTotal)

                    // const totalPoint = response?.data?.result?.reduce((total = 0, item) => {
                    //     return total + item.points;
                    // }, 0);

                    // setTotalCashIn(totalPoint);
                }
            } catch (error) {
                console.log("ListError", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const column = [

        {
            title: `DISTRIBUTOR`,
            dataIndex: 'name',
            key: `${bussinessReportRole}`,
            render: (_, record) => (
                <p>
                    {location?.state?.user?.userName ?? userDetail.userName}
                </p>
            ),
        },
        {
            title: 'PLAY POINTS',
            dataIndex: 'totalPlayPoints',
            key: 'Play Point',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'WON POINTS',
            dataIndex: 'totalWinPoints',
            key: 'Won Point',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'END POINTS',
            dataIndex: 'endPoints',
            key: 'End Point',
            render: text => converedTwoDecimal(text) || '0',

        },

        {
            title: 'NET',
            dataIndex: 'totalNetPoints',
            key: 'Net',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'PROFIT',
            dataIndex: 'totalProfitPoints',
            key: 'Admin Profit',
            render: text => converedTwoDecimal(text) || '0',
        },

        ...((profit?.PR !== undefined && profit?.PR !== null) ? [

            {
                title: 'PR',
                dataIndex: 'PR',
                key: 'PR',
                render: (text, record) => {
                    if (profit?.PR !== undefined && profit?.PR !== null) {
                        return profit.PR || '0';
                    }
                    return null;
                },
            },
        ] : []),
        {
            title: 'BONUS',
            dataIndex: 'Bonus',
            key: 'Bonus',
            render: text => converedTwoDecimal(text) || '0', // Use a render function to ensure consistency
        },
        ...((profit?.Dst !== undefined && profit?.Dst !== null) ? [
            {
                title: 'DST',
                dataIndex: 'DST',
                key: 'Dst',
                render: (text, record) => {
                    if (profit?.Dst !== undefined && profit?.Dst !== null) {
                        return profit?.Dst || '0';
                    }
                    return null;
                },
            },
        ] : []),
        ...((profit?.SD !== undefined && profit?.SD !== null) ? [

            {
                title: 'SD',
                dataIndex: 'SD',
                key: 'SD',
                render: (text, record) => {
                    if (profit?.SD !== undefined && profit?.SD !== null) {
                        return profit.SD || '0';
                    }
                    return null;
                },
            },
        ] : []),

        ...((profit?.AG !== undefined && profit?.AG !== null) ? [

            {
                title: 'AG',
                dataIndex: 'AG',
                key: 'AG',
                render: (text, record) => {
                    if (profit?.AG !== undefined && profit?.AG !== null) {
                        return profit.AG || '0';
                    }
                    return null;
                },
            },
        ] : []),
        ...((profit?.PL !== undefined && profit?.PL !== null) ? [
            {
                title: 'PL',
                dataIndex: 'PL',
                key: 'PL',
                render: (text, record) => {
                    if (profit?.PL !== undefined && profit?.PL !== null) {
                        return profit.PL || '0';
                    }
                    return null;
                },
            },
        ] : []),

    ];

    const columns = [
        {
            title: '#',
            dataIndex: '_id',
            key: 'Srno',
            render: (_, record, index) => {
                return (
                    <>
                        {(currentPage - 1) * pageSize + index + 1}
                    </>
                )
            }
        },
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'User Name',
            render: (_, record) => (
                <button style={{ border: 'none', color: '#2596be', background: 'none', fontWeight: 500 }} onClick={() => navigate(`/WinLucky/agent/bussiness-report`, { state: record })}>
                    {record.name}
                </button>
            ),
        },
        {
            title: 'Play Point',
            dataIndex: 'totalPlayPoints',
            key: 'Play Point',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'Won Point',
            dataIndex: 'totalWinPoints',
            key: 'Won Point',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'End Point',
            dataIndex: 'endPoints',
            key: 'End Point',
            render: (_, record) => {
                const endPoint = (record?.totalPlayPoints || 0) - (record?.totalWinPoints || 0);
                return <>{converedTwoDecimal(endPoint)}</>;
            }

        },
        {
            title: 'Margin',
            // dataIndex: 'totalAgentCommission',
            key: 'Margin',
            render: text => converedTwoDecimal(text) || '0',
            render: (_, record) => {
                const endPoint = ((record?.totalSubDistributorCommission || 0) + (record?.totalAgentCommission || 0));
                return <>{converedTwoDecimal(endPoint)}</>;
            }
        },
        {
            title: 'Net',
            dataIndex: 'net',
            key: 'Net',
            render: (_, record) => {
                const endPoint = ((record?.totalPlayPoints || 0) - (record?.totalWinPoints || 0)) - ((record?.totalSubDistributorCommission || 0) + (record?.totalAgentCommission || 0));
                return <>{converedTwoDecimal(endPoint)}</>;
            }
        },
        {
            title: 'Distributor Profit',
            dataIndex: 'totalDistributorCommission',
            key: 'Distributor Profit',
            render: text => converedTwoDecimal(text) || '0',
        },

        {
            title: 'Sub Distributor Profit',
            dataIndex: 'totalSubDistributorCommission',
            key: 'Sub Distributor Profit',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'Agent Profit',
            dataIndex: 'totalAgentCommission',
            key: 'Agent Profit',
            render: text => converedTwoDecimal(text) || '0',
        },
        {
            title: 'Bonus',
            dataIndex: 'Bonus',
            key: 'Bonus',
            render: text => converedTwoDecimal(text) || '0',

        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            key: 'Detail',
            render: (_, record) => (
                // <button style={{ border: 'none', color: '#2596be', background: 'none', fontWeight: 500 }} onClick={() => navigate(`/WinLucky/${bussinessReportRole}/bussiness-report-detail`, { state: record })}>
                //     DETAILS
                // </button>
                <button style={{ border: 'none', color: '#2596be', background: 'none', fontWeight: 500 }} onClick={() => {
                    setInsideBussinessReportDetail(record)
                    setIsInsideBussinessReportDetailShow(true)
                }
                }>
                    DETAILS
                </button>
            ),
        }

    ];
    const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async () => {
            const { pickDate, startDate, endDate, user, reportPassword, device, distributor, subDistributor, agent, partner } = values;
            await getBussinessReportList(pickDate, endDate, startDate, user, location?.state?.user?._id ? "goalocal123" : reportPassword, device, distributor, subDistributor, agent, partner);
        },
    });


    return (
        <main>

            {
                (isInsideBussinessReportDetailShow && insideBussinessReportDetail) ? <InsideBussinessReportDetail data={insideBussinessReportDetail} bussinessReportRole={bussinessReportRole} setIsInsideBussinessReportDetailShow={setIsInsideBussinessReportDetailShow} /> : (
                    <Container className="admin_padding">
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "14px 0", alignItems: "center", padding: "0 10px" }} className="table-hader-container">
                            <div className=" breadcumb">
                                <h1 >{bussinessReportRole?.toUpperCase()} DETAIL BUSINESS REPORT</h1>
                                <p className="fw-bold "><span style={{ color: '#2596be', cursor: 'pointer' }} onClick={() => { navigate("/") }}>Home </span> / {bussinessReportRole?.charAt(0)?.toUpperCase() + bussinessReportRole?.slice(1).toLowerCase()} Detail Bussiness Report </p>
                            </div>

                        </div>

                        <div className="admin_form" style={{ width: '98%', marginBottom: '20px' }}>
                            <div className="detail-heading"  >
                                <h6 className="text-white" style={{ backgroundColor: '#fc6c5c' }}>FILTER</h6>
                            </div>
                            <Row className="admin_add_form">

                                <Col>
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row>
                                            {
                                                (bussinessReportRole == 'sub-distributor') &&
                                                <Col className="mb-3" md={6}>
                                                    <Form.Group controlId="validationFormik01">
                                                        <Form.Label>
                                                            SELECT SUB DISTRIBUTOR
                                                        </Form.Label>
                                                        <Form.Select
                                                            type="text"
                                                            name="subDistributor"
                                                            value={values.subDistributor}
                                                            onChange={(e) => {
                                                                setFieldValue("subDistributor", e.target.value)

                                                            }}
                                                        >
                                                            <option value="">All Sub Distributor</option>
                                                            {subDisributorList && subDisributorList.length > 0 && subDisributorList.map((item, index) => {
                                                                return (
                                                                    <option value={item?.id} >{item?.userName}</option>
                                                                )
                                                            })}
                                                        </Form.Select>

                                                    </Form.Group>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>

                                            {!location?.state?.user &&
                                                <Col md={6} className="mb-3">
                                                    <Form.Group controlId="validationFormik06">
                                                        <Form.Label>REPORT PASSWORD</Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            name="reportPassword"
                                                            placeholder="Enter Report Password"
                                                            value={values.reportPassword}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            }

                                            <Col md={6} className="mb-3">
                                                <Form.Group controlId="validationFormik01">
                                                    <Form.Label>START DATE</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="startDate"
                                                        value={values.startDate}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md={6} className="mb-3">
                                                <Form.Group controlId="validationFormik02">
                                                    <Form.Label>END DATE</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="endDate"
                                                        value={values.endDate}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Label>DEVICE</Form.Label>
                                                <div className="d-flex">
                                                    <Form.Check
                                                        type="radio"
                                                        id="devicePC"
                                                        name="device"
                                                        label="PC"
                                                        value="PC"
                                                        checked={values.device === 'PC'}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="deviceMobile"
                                                        name="device"
                                                        label="MOBILE"
                                                        value="MOBILE"
                                                        checked={values.device === 'MOBILE'}
                                                        onChange={handleChange}
                                                        style={{ marginLeft: '10px' }}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="deviceAll"
                                                        name="device"
                                                        label="ALL"
                                                        value="ALL"
                                                        checked={values.device === 'ALL'}
                                                        onChange={handleChange}
                                                        style={{ marginLeft: '10px' }}

                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">

                                            <Col md={6} >
                                                <Form.Group controlId="validationFormik03">
                                                    <Form.Label>PICK DATE</Form.Label>
                                                    <Form.Select
                                                        name="pickDate"
                                                        value={values.pickDate}
                                                        onChange={handleChange}
                                                    >
                                                        <option value=''>SELECTED DATE</option>
                                                        <option value='today'>TODAY</option>
                                                        <option value='yesterday'>YESTERDAY</option>
                                                        <option value='thisWeek'>THIS WEEK</option>
                                                        <option value='lastWeek'>LAST WEEK</option>
                                                        <option value='thisMonth'>THIS MONTH</option>
                                                        <option value='lastMonth'>LAST MONTH</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <div style={{ display: "flex", justifyContent: "center" }} className="footer-btns">
                                            <Button type="submit" className="input_file_button">
                                                SUBMIT
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>

                            </Row>
                        </div>


                        {isShowTable &&
                            <>
                                <div className="admin_padding_table" style={{ padding: 0, marginTop: '15px' }}>
                                    <div className="detail-heading"  >
                                        <h6 className="text-white" style={{ backgroundColor: '#146eb4' }}>SELF REPORT</h6>
                                    </div>



                                    <div className="table-container" style={{ margin: "20px" }}>
                                        {loading && (
                                            <div className="spinner-overlay">
                                                <Spinner animation="border" />
                                            </div>
                                        )}
                                        <Table bordered dataSource={bussinessReportData} columns={column} scroll={{ x: 525 }}
                                            pagination={false}
                                            onChange={handleTableChange}
                                        />

                                    </div>
                                </div>
                                <div className="admin_padding_table" style={{ padding: 0, marginTop: '15px' }}>
                                    <div className="detail-heading"  >
                                        <h6 className="text-white" style={{ backgroundColor: '#146eb4', padding: "1rem" }}></h6>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            margin: "14px 0",
                                            alignItems: "center",
                                            padding: "0 10px",
                                        }}
                                        className="table-hader-container"
                                    >
                                        <div className="page-size">
                                            <span className="fw-bold">Show</span>
                                            <select onChange={(e) => setPageSize(e.target.value)}
                                                value={pageSize} className="selecte-page-size">
                                                <option value='10'>10</option>
                                                <option value='20'>25</option>
                                                <option value='50'>50</option>
                                                <option value='50'>100</option>
                                            </select>
                                            <span className="fw-bold">entries</span>
                                        </div>

                                        <div className="search_container">
                                            <Input
                                                placeholder="Search"
                                                prefix={<SearchOutlined />}
                                                onChange={(e) => setSearch(e.target.value)}
                                                value={search}
                                                className="search_input"
                                                style={{
                                                    borderRadius: "5px",
                                                    height: "40px",
                                                }}
                                            />
                                        </div>

                                    </div>



                                    <div className="table-container" style={{ margin: "20px" }}>
                                        {loading && (
                                            <div className="spinner-overlay">
                                                <Spinner animation="border" />
                                            </div>
                                        )}
                                        <Table bordered dataSource={bussinessReportList} columns={columns} scroll={{ x: 525 }}
                                            pagination={{
                                                current: currentPage,
                                                pageSize: pageSize,
                                                showSizeChanger: false,
                                                total: bussinessReportList?.length,
                                                onChange: (page, pageSize) => {
                                                    setCurrentPage(page);
                                                },
                                                showTotal: (total, range) => `showing ${range[0]}-${range[1]} of ${total} entries`,
                                            }}

                                            onChange={handleTableChange}
                                            summary={(pageData) => {
                                                let totalPlayPoint = 0;
                                                let totalWonPoint = 0;
                                                let totalEndPoint = 0;
                                                let totalNet = 0;
                                                let totalAdminProfit = 0
                                                let totalPartnerProfit = 0
                                                let totalDistributorProfit = 0
                                                let totalSubDistributorProfit = 0
                                                let totalAgentProfit = 0



                                                pageData.forEach((data) => {
                                                    totalPlayPoint += data.totalPlayPoints;
                                                    totalWonPoint += data.totalWinPoints;
                                                    totalEndPoint += data.totalPlayPoints - data.totalWinPoints;
                                                    totalAdminProfit += data.totalAdminCommission
                                                    totalPartnerProfit += data.totalPartnerCommission
                                                    totalDistributorProfit += data.totalDistributorCommission
                                                    totalSubDistributorProfit += data.totalSubDistributorCommission
                                                    totalAgentProfit += data.totalAgentCommission

                                                    totalNet += ((data?.totalPlayPoints || 0) - (data?.totalWinPoints || 0)) - ((data?.totalSubDistributorCommission || 0) + (data?.totalAgentCommission || 0));



                                                });
                                                return (
                                                    <>
                                                        <Table.Summary.Row style={{ backgroundColor: '#808080', fontWeight: 'bold',color: "#fff" }}>
                                                            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={2}>
                                                                <Text>{converedTwoDecimal(totalPlayPoint)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={5}>
                                                                <Text>{converedTwoDecimal(totalWonPoint)}</Text>
                                                            </Table.Summary.Cell> <Table.Summary.Cell index={6}>
                                                                <Text>{converedTwoDecimal(totalEndPoint)}</Text>
                                                            </Table.Summary.Cell>
                                                            {bussinessReportRole != 'user' &&
                                                                <>
                                                                    <Table.Summary.Cell index={7}>
                                                                        <Text></Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={8}>
                                                                        <Text>{converedTwoDecimal(totalNet)}</Text>
                                                                    </Table.Summary.Cell>
                                                                </>
                                                            }

                                                            <Table.Summary.Cell index={9}>
                                                                <Text>{converedTwoDecimal(totalDistributorProfit)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={10}>
                                                                <Text>{converedTwoDecimal(totalSubDistributorProfit)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={11}>
                                                                <Text>{converedTwoDecimal(totalAgentProfit)}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={12}>
                                                                <Text>0</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={13}>
                                                                <Text></Text>
                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>

                                                    </>
                                                );
                                            }}
                                        />

                                    </div>
                                </div>
                            </>
                        }
                    </Container>
                )
            }

        </main >
    );
};

export default SubDistributorBussinessReport;

