import React from "react";
import { Container, Spinner } from "react-bootstrap";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { Space, Table, Popconfirm, Button, Image, Input } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import useToast from "../../provider/Toast/useToast";
import { apiAdminConfig } from "../../utils/api";
import moment from "moment";
import { SearchOutlined } from '@ant-design/icons';
import {converedTwoDecimal} from "../../utils/cookie"


const Register = () => {
    const [registerList, setRegisterList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(25);

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const toast = useToast();

    const navigate = useNavigate();

    const getRegisterList = async () => {
        setLoading(true)
        await apiAdminConfig.get(`users/get?role=admin&search=${search}`).then((response) => {
            if (response?.status == 200) {
                setRegisterList(response?.data?.results)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false)
            console.log("registerListError", error);
        })
    }
    React.useEffect(() => {
        getRegisterList();
    }, [search])

    const onDelete = async (id) => {
        await apiAdminConfig.delete(`users/delete/${id}`).then((response) => {
            if (response?.status === 200) {
                toast({
                    message: 'Admin Delete successfully',
                    bg: 'success'
                });
                getRegisterList();
            } else {
                toast({
                    message: response?.data?.message,
                    bg: 'danger'
                });
            }
        }).catch((error) => {
            const { response } = error
            toast({
                message: response?.data?.message,
                bg: "danger",
            });
        })
    }
    const columns = [
        {
            title: 'SR NO',
            dataIndex: '_id',
            key: 'Srno',
            render: (_, record, index) => {
                return (
                    <>
                  {(currentPage - 1) * pageSize + index + 1}

                    </>
                )
            }
        },
        {
            title: 'USER NAME',
            dataIndex: 'userName',
            key: 'User Name',
            render: (_, record) => (
                <button style={{border:'none',color:'#2596be',background:'none',fontWeight:500}} onClick={() =>   navigate(`/WinLucky/admin/view`, { state: { data: record } })}>
                    {record.userName}
                </button>
            ),
        },
        {
            title: 'POINTS',
            dataIndex: 'points',
            key: 'Points',
            render: text => converedTwoDecimal(text) || '0'
        },
        {
            title: 'LAST LOGIN',
            dataIndex: 'lastLogin',
            key: 'Last Login',
            render: text => text ? moment(text).format('DD-MM-YYYY') : '-'
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            key: 'Email',
            render: text => text || '-'
        },
        {
            title: 'PHONE NO',
            dataIndex: 'phone',
            key: 'Phone No',
            render: text => text || '-'
        },
        {
            title: 'REGISTER DATE',
            dataIndex: 'createdAt',
            key: 'Register Date',
            render: text => text ? moment(text).format('DD-MM-YYYY') : '-'
        },
        // {
        //     title: 'Type',
        //     dataIndex: 'type',
        //     key: 'Type',
        // },
        // {
        //     title: 'Commission',
        //     dataIndex: 'commission',
        //     key: 'Commission',
        // },
        // {
        //     title: 'Locked By',
        //     dataIndex: 'lockedBy',
        //     key: 'Locked By',
        // },
        // {
        //     title: 'Lock Status',
        //     dataIndex: 'lockStatus',
        //     key: 'Lock Status',
        // },
        {
            title: "ACTION",
            dataIndex: "action",
            width: '20%',
            key: 'Action',
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        title="Edit"
                        style={{ padding: "2px 4px" }}
                        onClick={() => {
                            navigate(`/WinLucky/admin/form`, { state: { data: record } })
                        }}
                    >
                        {" "}
                        <strong>
                            {" "}
                            <EditOutlined
                                style={{
                                    fontWeight: "900",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                }}
                            />{" "}
                        </strong>{" "}
                    </Button>

                    <Popconfirm
                        title="Are you sure want to delete ?"
                        key={record.id}
                        onConfirm={() => onDelete(record.id)}
                    >
                        <Button danger type="primary" title="Delete" style={{ padding: "4px 8px" }}>
                            <strong>
                                <DeleteOutlined
                                    style={{
                                        fontWeight: "900",
                                        fontSize: "16px",
                                        verticalAlign: "middle",
                                    }}
                                />
                            </strong>
                        </Button>
                    </Popconfirm>
                    {/* <Button danger type="primary" title="View" style={{ padding: "4px 8px" }} onClick={() => {
                        navigate(`/WinLucky/admin/view`, { state: { data: record } })
                    }}>
                        <strong>
                            <EyeOutlined
                                style={{
                                    fontWeight: "900",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                }}
                            />
                        </strong>
                    </Button> */}
                </Space>
            ),
        }
    ]




    return (
        <main id="registerLists">
            <Container className="admin_padding">
            <div className="container breadcumb">
            <h1>ADMIN LIST</h1>
            <p className="fw-bold "><span style={{ color: '#2596be' ,cursor:'pointer'}} onClick={()=>{ navigate( "/")}}>Home </span> / Admin List</p>
                </div>
                <div className="admin_padding_table" style={{ padding: 0 ,marginTop:'15px'}}>
                <div className="detail-heading"  >
                        <h6 className="text-white" style={{ backgroundColor: '#146eb4', padding: "1rem" }}></h6>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                         <div className="page-size">
                            <span className="fw-bold">Show</span>
                            <select onChange={(e) => setPageSize(e.target.value)}
                                value={pageSize} className="selecte-page-size">
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                            </select>
                            <span className="fw-bold">entries</span>
                        </div>
                        <div className="search_container">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                className="search_input"
                                style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                }}
                            />
                            <Link to="/WinLucky/admin/form" className="page_add_btn">
                                Add New
                            </Link>
                        </div>

                    </div>
                    <div className="table-container">
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        {
                            loading ?
                                <Table dataSource={registerList} scroll={{ x: 525 }} />
                                :
                                <Table bordered  dataSource={registerList} columns={columns} style={{margin :'10px'}} scroll={{ x: 525 }}
                                    pagination={{
                                        current: currentPage,
                                        pageSize: pageSize,
                                        showSizeChanger: false,
                                        total: registerList.length,
                                        onChange: (page, pageSize) => {
                                            setCurrentPage(page);
                                        },
                                        showTotal: (total, range) => `showing ${range[0]}-${range[1]} of ${total} entries`,
                                    }}

                                    onChange={handleTableChange} />

                        }
                    </div>
                </div>
            </Container>
        </main>
    );
};

export default Register;

