import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "../LayoutAdmin/Sidebar";
import Login from "../Pages/login";
import Partner from "../Pages/Partner";
import PartnerFormModel from "../Pages/Partner/PartnerFormModel";
import Distributor from "../Pages/Distributor";
import DestributorFormModel from "../Pages/Distributor/DistributorFormModel";
import SubDestributor from "../Pages/SubDistributor";
import SubDestributorFormModel from "../Pages/SubDistributor/SubDistributorFormModel";
import Agent from "../Pages/Agent";
import UserFormModel from "../Pages/User/UserFormModel";
import User from "../Pages/User";
import Register from "../Pages/Register";
import RegisterFormModel from "../Pages/Register/RegisterFormModel";
import AgentFormModel from "../Pages/Agent/AgentFormModel";
import Dashboard from "../Pages/DashBoard";
import Game from "../Pages/Game";
import GameFormModel from "../Pages/Game/GameFormModel";
// import Commission from "../Pages/Commission";
// import CommissionFormModel from "../Pages/Commission/CommissionFormModel";
import RegisterViewModel from "../Pages/Register/RegisterViewModel";
import PartnerViewModel from "../Pages/Partner/PartnerViewModel";
import DestributorViewModel from "../Pages/Distributor/DistributorViewModel";
import SubDestributorViewModel from "../Pages/SubDistributor/SubDistributorViewModel";
import AgentViewModel from "../Pages/Agent/AgentViewModel";
import UserViewModel from "../Pages/User/UserViewModel";
import { Error } from "../Pages/Error";
import ResetPassword from "../Pages/ResetPasswords";
import PartnerPointModel from "../Pages/Partner/PartnerPointModel";
import DistributorPointModel from "../Pages/Distributor/DistributorPointModel";
import SubDistributorPointModel from "../Pages/SubDistributor/SubDistributorPointModel";
import AgentPointModel from "../Pages/Agent/AgentPointModel";
import UserPointModel from "../Pages/User/UserPointModel";
import TransctionIn from "../Pages/TransactionIn";
import TransctionOut from "../Pages/TransactionOut";
import PointFile from "../Pages/PointFile";
import GameTransaction from "../Pages/GameTransaction";
// import BussinessReport from "../Pages/BussinessReport";
import BussinessReportDetail from "../Pages/BussinessReportDetail";
import UserDetail from "../Pages/UserDetail";
import AdminAddPoint from "../Pages/AdminAddPoint";
import GameResult from "../Pages/GameResult";
import UserBussinessReport from "../Pages/BussinessReport/UserBusinessReport";
import AgentBusinessReport from "../Pages/BussinessReport/AgentBusinessReport";
import SubDistributorBussinessReport from "../Pages/BussinessReport/SubDistributorBusinessReport";
import DistributorBussinessReport from "../Pages/BussinessReport/DistributorBusinessReport";
import PartnerBusinessReport from "../Pages/BussinessReport/PartnerBusinessReport";
function AdminRoute() {
  const data = localStorage.getItem('userDetail')
  const userData = JSON.parse(data)
  const userRole = userData?.role;


  return (
    <Routes>
      <Route path="/" element={<Sidebar />}>
        <Route index element={<Dashboard />} />
        {(userRole == "superAdmin") &&
          <>
            <Route path="/WinLucky/admin" element={<Register />} />
            <Route path="/WinLucky/admin/form" element={<RegisterFormModel />} />
            <Route path="/WinLucky/admin/view" element={<RegisterViewModel />} />
          </>}
        {(userRole == "admin") &&
          <Route path="WinLucky/add-point" element={<AdminAddPoint />} />
        }

        {(userRole == "admin" || userRole == "superAdmin") &&
          <>
            <Route path="/WinLucky/partner" element={<Partner />} />
            <Route path="/WinLucky/partner/form" element={<PartnerFormModel />} />
            <Route path="/WinLucky/partner/view" element={<PartnerViewModel />} />
            <Route path="/WinLucky/partner/point" element={<PartnerPointModel />} />
            {/* <Route path="/WinLucky/partner/bussiness-report" element={<BussinessReport />} /> */}
            <Route path="/WinLucky/partner/bussiness-report-detail" element={<BussinessReportDetail />} />


            <Route path="/WinLucky/game" element={<Game />} />
            <Route path="/WinLucky/game/form" element={<GameFormModel />} />
            {/* <Route path="/commission" element={<Commission />} />
            <Route path="/commission/form" element={<CommissionFormModel />} /> */}
          </>}
        {(userRole == "admin" || userRole == "partner" || userRole == "superAdmin") &&
          <>
            <Route path="/WinLucky/distributor" element={<Distributor />} />
            <Route path="/WinLucky/distributor/form" element={<DestributorFormModel />} />
            <Route path="/WinLucky/distributor/view" element={<DestributorViewModel />} />
            <Route path="/WinLucky/distributor/point" element={<DistributorPointModel />} />
            {/* <Route path="/WinLucky/distributor/bussiness-report" element={<BussinessReport />} /> */}
            <Route path="/WinLucky/distributor/bussiness-report-detail" element={<BussinessReportDetail />} />

          </>
        }
        {(userRole == "admin" || userRole == "distributor" || userRole == "partner" || userRole == "superAdmin") &&
          <>
            <Route path="/WinLucky/sub-distributor" element={<SubDestributor />} />
            <Route path="/WinLucky/sub-distributor/form" element={<SubDestributorFormModel />} />
            <Route path="/WinLucky/sub-distributor/view" element={<SubDestributorViewModel />} />
            <Route path="/WinLucky/sub-distributor/point" element={<SubDistributorPointModel />} />
            {/* <Route path="/WinLucky/sub-distributor/bussiness-report" element={<BussinessReport />} /> */}
            <Route path="/WinLucky/sub-distributor/bussiness-report-detail" element={<BussinessReportDetail />} />
          </>
        }
        {(userRole == "admin" || userRole == "subDistributor" || userRole == "distributor" || userRole == "partner" || userRole == "superAdmin") &&
          <>
            <Route path="/WinLucky/agent" element={<Agent />} />
            <Route path="/WinLucky/agent/form" element={<AgentFormModel />} />
            <Route path="/WinLucky/agent/view" element={<AgentViewModel />} />
            <Route path="/WinLucky/agent/point" element={<AgentPointModel />} />
            {/* <Route path="/WinLucky/agent/bussiness-report" element={<BussinessReport />} /> */}
            <Route path="/WinLucky/agent/bussiness-report-detail" element={<BussinessReportDetail />} />
          </>
        }
        {(userRole == "admin" || userRole == "agent" || userRole == "partner" || userRole == "distributor" || userRole == "subDistributor" || userRole == "superAdmin") &&
          <>
            <Route path="/WinLucky/user" element={<User />} />
            <Route path="/WinLucky/user/form" element={<UserFormModel />} />
            <Route path="/WinLucky/user/view" element={<UserViewModel />} />
            <Route path="/WinLucky/user/point" element={<UserPointModel />} />
            <Route path="/WinLucky/user/bussiness-report-detail" element={<BussinessReportDetail />} />
          </>
        }

        {(<Route path="/WinLucky/user/bussiness-report" element={<UserBussinessReport />} />)}
        {(<Route path="/WinLucky/agent/bussiness-report" element={<AgentBusinessReport />} />)}
        {(<Route path="/WinLucky/sub-distributor/bussiness-report" element={<SubDistributorBussinessReport />} />)}
        {(<Route path="/WinLucky/distributor/bussiness-report" element={<DistributorBussinessReport />} />)}
        (<Route path="/WinLucky/partner/bussiness-report" element={<PartnerBusinessReport />} />)

        {(userRole !== "superAdmin") &&
          <>
            <Route path="/WinLucky/transaction-out" element={<TransctionOut />} />
            <Route path="/WinLucky/transaction-in" element={<TransctionIn />} />
            <Route path="/WinLucky/point-file" element={<PointFile />} />
            <Route path="/WinLucky/game-transaction" element={<GameTransaction />} />
            <Route path="/WinLucky/user-detail" element={<UserDetail />} />

          </>}
        <Route path="/WinLucky/reset-password" element={<ResetPassword />} />
      </Route>
      <Route path="WinLucky/game-result" element={<GameResult />} />

      <Route path="/WinLucky/login" element={<Login />} />
      <Route path='/WinLucky/error/404' element={<Error />} />

      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  );
}

export default AdminRoute;


