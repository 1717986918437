// import React, { useEffect, useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tdle, Tooltip, Legend } from 'chart.js';
// import { apiAdminConfig } from "../../utils/api";

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const GamePercentageChart = () => {
//     const[playpoint,setPlayPoint]=useState([])
//     const[wonpoint,setWonPoint]=useState([])
//     const[endpoint,setEndPoint]=useState([])

//     const getDateWisePoint = async(startDate='',endDate='',gameId='') => {
//         await apiAdminConfig.get(`users/dashboard?startDate=${startDate}&endDate=${endDate}&gameId=${gameId}`).then((response) => {
//            console.log("response",response)
//             if (response?.status == 200) {
//             }
//           }).catch((error) => {
//             console.log("error", error);
//           })
//     }

//     useEffect(() => {
//         getDateWisePoint()
//     }, [])
//     const data = {
//         labels: ['Oct 21', 'Oct 23', 'Oct 25', 'Oct 27', 'Oct 29', 'Oct 31', 'Nov 2'],
//         datasets: [
//             {
//                 label: 'Play Point',
//                 data: [1000000, 300000, 700000, 400000, 200000, 500000, 600000],
//                 borderColor: '#00BFFF',
//                 backgroundColor: '#00BFFF',
//                 pointStyle: 'circle',
//                 pointRadius: 6,
//                 fill: false,
//                 yAxisID: 'y1',
//             },
//             {
//                 label: 'Won Point',
//                 data: [900000, 200000, 500000, 400000, 200000, 500000, 600000],
//                 borderColor: '#800080',
//                 backgroundColor: '#800080',
//                 pointStyle: 'triangle',
//                 pointRadius: 6,
//                 fill: false,
//                 yAxisID: 'y2',
//             },
//             {
//                 label: 'End Point',
//                 data: [600000, 100000, 400000, 400000, 200000, 500000, 600000],
//                 borderColor: '#8A2BE2',
//                 backgroundColor: '#8A2BE2',
//                 pointStyle: 'square',
//                 pointRadius: 6,
//                 fill: false,
//                 yAxisID: 'y3',
//             },
//         ],
//     };

//     const options = {
//         responsive: true,
//         plugins: {
//             legend: {
//                 position: 'bottom',
//             },
//             title: {
//                 display: true,
//                 text: 'Game Percentage',
//             },
//         },
//         scales: {
//             y1: {
//                 type: 'linear',
//                 position: 'left',
//                 beginAtZero: true,
//                 title: {
//                     display: true,
//                     color: '#00BFFF',
//                 },
//                 ticks: {
//                     color: '#00BFFF',
//                 },
//                 grid: {
//                     drawOnChartArea: false,
//                     color: '#00BFFF',
//                 },
//                 border: {
//                     color: '#00BFFF', // Color for the Y-axis line
//                 },
//             },
//             y2: {
//                 type: 'linear',
//                 position: 'left',
//                 beginAtZero: true,
//                 title: {
//                     display: true,
//                     color: '#800080',
//                 },
//                 ticks: {
//                     color: '#800080',
//                 },
//                 grid: {
//                     drawOnChartArea: false,
//                     color: '#800080',
//                 },
//                 border: {
//                     color: '#800080', // Color for the Y-axis line
//                 },
//             },
//             y3: {
//                 type: 'linear',
//                 position: 'right',
//                 beginAtZero: true,
//                 title: {
//                     display: true,
//                     color: '#8A2BE2',
//                 },
//                 ticks: {
//                     color: '#8A2BE2',
//                 },
//                 grid: {
//                     drawOnChartArea: false,
//                     color: '#8A2BE2',
//                 },
//                 border: {
//                     color: '#8A2BE2', // Color for the Y-axis line
//                 },
//             },
//             x: {
//                 title: {
//                     display: true,
//                     text: 'Date',
//                 },
//             },
//         },
//     };

//     return <Line data={data} options={options} />;
// };

// export default GamePercentageChart;



// import React from 'react';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// // Registering the components for chart.js
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const LineChartComponent = () => {
//   // Data for the line chart
//   const data = {
//     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//     datasets: [
//       {
//         label: 'Monthly Sales',
//         data: [65, 59, 80, 81, 56, 55, 40],
//         fill: false,
//         borderColor: 'rgb(75, 192, 192)',
//         tension: 0.1,
//       },
//       {
//         label: 'yearly Sales',
//         data: [12, 69, 80, 45, 76, 25, 30],
//         fill: false,
//         borderColor: 'rgb(201, 182, 96)',
//         tension: 0.1,
//       },
//       {
//         label: 'current Sales',
//         data: [45, 79, 45, 63, 47, 15, 40],
//         fill: false,
//         borderColor: 'rgb(109, 177, 63)',
//         tension: 0.1,
//       },
      
//     ],
//   };

//   // Options to customize the chart (like axis, title, etc.)
//   const options = {
//     responsive: true,
//     plugins: {
//       title: {
//         display: true,
//         text: 'Monthly Sales Data',
//       },
//       tooltip: {
//         callbacks: {
//           label: function (tooltipItem) {
//             return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
//           },
//         },
//       },
//     },
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: 'Month',
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: 'Sales',
//         },
//         beginAtZero: true,
//       },
//     },
//   };

//   return (
//     <div>
//       <h2>Line Chart Example</h2>
//       <Line data={data} options={options} />
//     </div>
//   );
// };

// export default LineChartComponent;
// import React, { useState } from 'react';
// import { CategoryChart } from 'igniteui-react-charts';
// import { IgrCategoryChartModule } from 'igniteui-react-charts';
// import { IgrLegendModule } from 'igniteui-react-charts';

// const mods = [
//   IgrLegendModule,
//   IgrCategoryChartModule
// ];
// mods.forEach((m) => m.register());

// const LineChartMultipleSources = () => {
//   // Data sources using useState hooks
//   const [dataSource1] = useState([
//     { Year: '2010', Value: 0.12 },
//     { Year: '2011', Value: 0.14 },
//     { Year: '2012', Value: 0.16 },
//     { Year: '2013', Value: 0.18 },
//   ]);
  
//   const [dataSource2] = useState([
//     { Year: '2010', Value: 0.20 },
//     { Year: '2011', Value: 0.22 },
//     { Year: '2012', Value: 0.25 },
//     { Year: '2013', Value: 0.30 },
//   ]);

//   return (
//     <div>
//       <CategoryChart
//         dataSource={dataSource1}
//         xAxis={{
//           name: 'Year',
//           type: 'category',
//           label: 'Year',
//           title: 'Year',
//         }}
//         yAxis={{
//           name: 'Value',
//           label: 'Value',
//           title: 'Value',
//           minimumValue: 0,
//           maximumValue: 0.35,
//         }}
//         series={[
//           {
//             name: 'Series 1',
//             type: 'line',
//             xMemberPath: 'Year',
//             yMemberPath: 'Value',
//             trendLineType: 'none',
//             title: 'Series 1',
//             brush: '#1f77b4',
//             thickness: 2,
//           },
//         ]}
//       />
//       <CategoryChart
//         dataSource={dataSource2}
//         xAxis={{
//           name: 'Year',
//           type: 'category',
//           label: 'Year',
//           title: 'Year',
//         }}
//         yAxis={{
//           name: 'Value',
//           label: 'Value',
//           title: 'Value',
//           minimumValue: 0,
//           maximumValue: 0.35,
//         }}
//         series={[
//           {
//             name: 'Series 2',
//             type: 'line',
//             xMemberPath: 'Year',
//             yMemberPath: 'Value',
//             trendLineType: 'none',
//             title: 'Series 2',
//             brush: '#ff7f0e',
//             thickness: 2,
//           },
//         ]}
//       />
//     </div>
//   );
// };

// export default LineChartMultipleSources;


import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChartComponent = () => {
  // Sample data for the chart
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'], // X-axis labels
    datasets: [
      {
        label: 'Dataset 1', // Name of the dataset
        data: [65, 59, 80, 81, 56, 55, 40], // Data points for the dataset
        borderColor: 'rgba(75, 192, 192, 1)', // Line color
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // Area fill color under the line
        fill: true, // Whether to fill the area under the line
        tension: 0.4, // Curve of the line (0.4 makes it slightly curved)
      },
      {
        label: 'Dataset 2', // Second dataset
        data: [28, 48, 40, 19, 86, 27, 90],
        borderColor: 'rgba(153, 102, 255, 1)', // Line color for dataset 2
        backgroundColor: 'rgba(153, 102, 255, 0.2)', // Area fill color for dataset 2
        fill: true,
        tension: 0.4,
      },
    ],
  };

  // Options to customize the chart (like title, axes, etc.)
  const options = {
    responsive: true, // Make the chart responsive to screen size
    plugins: {
      title: {
        display: true,
        text: 'Monthly Data', // Chart title
      },
      legend: {
        position: 'top', // Position of the legend (top, bottom, etc.)
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months', // X-axis title
        },
      },
      y: {
        title: {
          display: true,
          text: 'Values', // Y-axis title
        },
        min: 0, // Minimum value of Y-axis
        max: 100, // Maximum value of Y-axis
      },
    },
  };

  return (
    <div>
      <h2>Line Chart Example</h2>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChartComponent;
